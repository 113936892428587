/* 
 * @Author: 曹俊杰 
 * @Date: 2021-02-01 15:55:31
 * @Module: 前往App观看
 */
 <template>
  <div class="toAppModule">
    <el-dialog :visible.sync="dialogVisible"
               :show-close="false"
               width="30%">
      <div class="toAppModule-box">
        <div class="iconfont iconxingzhuang toAppModule-box-t"></div>
        <div class="toAppModule-box-c">{{title}}</div>
        <div class="toAppModule-box-b">{{info}}</div>
      </div>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   size="small"
                   @click="okBtn">确 定</el-button>
        <el-button @click="dialogVisible = false"
                   size="small">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  props: {
    title: {
      type: String,
      default: "请前往网红头条APP观看"
    },
    info: {
      type: String,
      default: "请前往网红头条APP观看"
    }
  },
  components: {},
  data () {
    return {
      dialogVisible: false,
      href: "http://fir.wanghongtoutiao.cn/whtt"
    };
  },
  mounted () { },
  methods: {
    showModule () {
      this.dialogVisible = true
    },
    okBtn () {
      window.open(this.href)
      this.dialogVisible = false
    }
  },
};
 </script>
 <style lang='scss' scoped>
.toAppModule {
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-t {
      font-size: 70px;
      color: #ff4d4f;
    }
    &-c {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 32px;
      margin-top: 29px;
    }
    &-b {
      margin-top: 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
}
</style>